import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {FinishedQuizByUser, Footer, Header, QuizQuestionsByQuiz} from "./Public";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {publicQuizService, userAccountService} from "../../_services";
import defaultImage from "../../assets/images/global/default_image.jpg";

const QuizQuestions = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let {qid} = useParams();
    const {t, i18n} = useTranslation();
    const [quizData, setData] = useState('');
    const [loading, setLoading] = useState(true);
    const [quizFinished, setQuizFinished] = useState(false);
    const [quizStarted, setQuizStarted] = useState(false);
    const [score, setScore] = useState('');
    const [canStartQuiz, setCanStartQuiz] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicQuizService.getQuizById(qid);
                setData(data);
            } catch (error) {
                console.error("Error fetching Quiz data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const getTitle = (category) => {
        return i18n.language.startsWith('fr') ? category.title_fr : category.title_en;
    };

    const getDescription = (category) => {
        return i18n.language.startsWith('fr') ? category.description_fr : category.description_en;
    };

    const makeQuizFinished = () => {
        setQuizFinished(true)
    }

    const makeQuizStarted = () => {
        if (userAccountService.isLogged()) {
            setQuizStarted(true);
        } else {
            navigate('/auth/login', { state: { from: location.pathname } });
        }
    }

    const setScoreString = (scoreString) => {
        setScore(scoreString)
    }

    let baseURL = 'https://api.holylane.io/';

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="quizzes-by-category-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                {loading ? (
                    <div className="main-loading-effect w-75 mx-auto mt-3 rounded-2" style={{height: "20px"}}></div>
                ) : (
                    quizData && (
                        <div className="p-3 bottom-padding">
                            {quizFinished ? (
                                <div className="quiz-finished-container">
                                    <div className="fw-bold-500 small text-center main-text-dark-color">
                                        Congratulations, you've completed this quiz!
                                    </div>
                                    <div className="black-text-color text-center mb-2 fw-bold-500">
                                        Your score: <span className="fw-bold">{score}</span>
                                    </div>
                                    <div className="px-3 mt-4">
                                        <FinishedQuizByUser/>
                                        <div className="gray-text-color small mt-3 text-center">
                                            Let's keep testing your knowledge by playing more quizzes!
                                        </div>
                                        <Link to="/bible-quizzes"
                                              className="mt-4 mx-auto text-white d-block text-center main-hover-background-color w-75 p-3 rounded-3">
                                            Explore More
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    {quizStarted ? (
                                        <div>
                                            <div
                                                className="quiz-category-title text-center fw-bold-600 main-text-dark-color">
                                                {getTitle(quizData)}
                                            </div>
                                            {quizData.questions_number > 0 && (
                                                <QuizQuestionsByQuiz
                                                    quiz={qid}
                                                    setScoreString={setScoreString}
                                                    setQuizFinished={makeQuizFinished}
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="main-quiz-details-container">
                                            <div className="cover-holder mb-2">
                                                {quizData.image ? (
                                                    <img
                                                        src={`${baseURL}thedss/images/quizzes/${quizData.image}`}
                                                        alt="Current Image For Quiz"
                                                        className="rounded-2 w-100"
                                                        height="225"
                                                    />
                                                ) : (
                                                    <img
                                                        src={defaultImage}
                                                        alt="Default Image For Ad"
                                                        className="rounded-2 w-100 border"
                                                        height="225"
                                                    />
                                                )}
                                            </div>
                                            <div
                                                className="quiz-category-title text-center fw-bold-600 main-text-dark-color">
                                                {getTitle(quizData)}
                                            </div>
                                            <div
                                                className="d-flex align-items-center justify-content-between px-3 mt-2 black-text-color">
                                                <div className="small">
                                                    Level:
                                                    <span className="ms-1">
                                                        {quizData.level === 1 ? 'Easy' : quizData.level === 2 ? 'Medium' : 'Hard'}
                                                    </span>
                                                </div>
                                                <div className="small">

                                                    {`${quizData.questions_number} Question${quizData.questions_number > 1 ? 's' : ''}`}
                                                </div>
                                            </div>
                                            <div className="about-title mt-3 small fw-bold-600 mb-2">
                                                About the Quiz
                                            </div>
                                            <div className="description small gray-text-color lh-lg">
                                                {getDescription(quizData)}
                                            </div>
                                            <div className="start-btn-holder text-center mt-4">
                                                <button
                                                    onClick={makeQuizStarted}
                                                    type="button"
                                                    className="border-0 rounded-2 py-2 px-5 main-hover-background-color text-white d-inline-block">
                                                    Get Started
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )
                )}
                <Footer/>
            </div>
        </div>
    );
};

export default QuizQuestions;