import {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {adminDashboardService} from "../../../_services";

const RevenueDetails = () => {
    const [filterBy, setFilterBy] = useState('days');
    const [loading, setLoading] = useState(true);
    const [series, setSeries] = useState([
        {name: 'Sales', data: [0, 0, 0, 0, 0, 0, 0]},
        {name: 'Subscriptions', data: [0, 0, 0, 0, 0, 0, 0]}
    ]);
    const [options, setOptions] = useState({
        chart: {
            type: 'bar',
            zoom: {enabled: false},
            toolbar: {show: false},
        },
        grid: {
            borderColor: '#f1f1f1',
            row: {colors: ['#fff'], opacity: 0.5}
        },
        dataLabels: {enabled: false},
        colors: ["#4fe396", "#f6b01b"],
        xaxis: {categories: []},
        stroke: {width: 2, colors: ['#fff']},
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 10,
        },
        yaxis: {
            labels: {
                formatter: val => `$${val}`
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
                columnWidth: '55%',
                endingShape: 'rounded'
            }
        },
        fill: {opacity: 1},
        tooltip: {
            y: {
                formatter: val => `$${val}`
            }
        },
    });

    useEffect(() => {
        const fetchOrdersStats = async () => {
            try {
                setLoading(true);
                const data = await adminDashboardService.getRevenueDetails(filterBy);

                setSeries([
                    {name: 'Sales', data: data.seriesResult.sales.map(value => parseFloat(value).toFixed(2))},
                    {name: 'Subscriptions', data: data.seriesResult.subscriptions.map(value => parseFloat(value).toFixed(2))}
                ]);

                setOptions(prevOptions => ({
                    ...prevOptions,
                    xaxis: {
                        ...prevOptions.xaxis,
                        categories: data.categoriesResult
                    }
                }));
            } catch (error) {
                console.error("Error fetching revenue details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchOrdersStats();
    }, [filterBy]);

    const changeFilterBy = (filterBy) => {
        setFilterBy(filterBy);
    };

    return (
        <div className="border border-light-subtle pt-3 pb-1 px-3 px-sm-2 px-md-3 bg-white light-shadow rounded-3">
            <div className="d-flex align-items-center justify-content-between">
                <div className="main-text-dark-color fw-bold-600">
                    Revenue
                </div>
                <div className="form-group">
                    <select
                        className="form-select form-select-sm text-real-small"
                        onChange={(e) => changeFilterBy(e.target.value)}
                        id="filterBy"
                    >
                        <option value="days">Days</option>
                        <option value="months">Months</option>
                        <option value="years">Years</option>
                    </select>
                </div>
            </div>
            {loading ? (
                <div className="main-loading-effect mt-2 mb-1 rounded-2" style={{height: "300px", width: "100%"}}/>
            ) : (
                <div className="">
                    <div id="chart">
                        <ReactApexChart options={options} series={series} type="bar" height={300}/>
                    </div>
                    <div id="html-dist"></div>
                </div>
            )}
        </div>
    );
};

export default RevenueDetails;