import ReactApexChart from "react-apexcharts";
import {useEffect, useState} from "react";
import {adminDashboardService} from "../../../_services";

const UsersStatus = () => {
    const [series, setSeries] = useState([0, 0]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRegisteredUsersStatus = async () => {
            try {
                setLoading(true);
                const data = await adminDashboardService.getRegisteredUsersStatus();
                setSeries(data.map(Number));
            } catch (error) {
                console.error("Error fetching registered users status:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRegisteredUsersStatus();
    }, []);

    const [options] = useState({
        chart: {
            type: 'donut',
        },
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
                offsetY: 10,
            },
        },
        grid: {
            padding: {
                bottom: -80,
            },
        },
        labels: ['Free', 'Paid'],
        colors: ['#008FFB', '#775DD0FF'],
    });

    return (
        <div
            className="border border-light-subtle pt-3 pb-1 px-3 px-sm-2 px-md-3 bg-white light-shadow rounded-3 semi-donut-holder 340 138">
            <div className="main-text-dark-color fw-bold-600">
                Users Status
            </div>
            {loading ? (
                <div className="main-loading-effect mt-2 mb-2 rounded-2" style={{height: "100px", width: "100%"}}/>
            ) : (
                <div className="">
                    <div id="chart" className="">
                        <ReactApexChart options={options} series={series} type="donut" height={125}/>
                    </div>
                    <div id="html-dist"></div>
                </div>
            )}
        </div>
    );
};

export default UsersStatus;