import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {publicQuizService} from "../../../_services";
import defaultUserImg from "../../../assets/images/global/default_user.png";

const GlobalRanking = () => {
    useEffect(() => {
        document.title = "Holylane - Bible Quizzes";
    }, []);

    const {t, i18n} = useTranslation();
    const [globalRankingData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await publicQuizService.getGlobalRanking();
                setData(data);
            } catch (error) {
                console.error("Error fetching Quiz global ranking data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    function getRankSuffix(rank) {
        const remainder = rank % 10;
        const tens = Math.floor((rank % 100) / 10);

        if (tens === 1) return `${rank}th`;

        switch (remainder) {
            case 1:
                return `${rank}st`;
            case 2:
                return `${rank}nd`;
            case 3:
                return `${rank}rd`;
            default:
                return `${rank}th`;
        }
    }

    let baseURL = 'https://api.holylane.io/';

    return (
        <div>
            {loading ? (
                <>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                    <div style={{height: "56px"}}
                         className="rounded-3 mb-3 main-loading-effect"/>
                </>
            ) : (
                globalRankingData.map((rankUser) => (
                    <div key={rankUser.id}>
                        <div
                            className="user-rank mb-3 d-flex align-items-center main-background-color text-white py-2 px-3 rounded-3">
                            <div className="rank small me-3">
                                {rankUser.total_points > 0 ? (
                                    getRankSuffix(rankUser.rank)
                                ) : (
                                    '0'
                                )}
                            </div>
                            <div className="img-holder me-2">
                                <img src={defaultUserImg} width="40" height="40" alt=""/>
                            </div>
                            <div className="fw-bold-500 small">
                                {rankUser.full_name || ''}
                            </div>
                            <div className="lh-sm ms-auto text-end">
                                <div>
                                    {rankUser.quizzes_taken}
                                </div>
                                <div className="text-real-small">
                                    Quizzes
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
};

export default GlobalRanking;