import {useEffect, useState} from "react";
import {adminDashboardService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

const LatestSales = () => {
    const [latestSalesData, setLatestSalesData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLatestSales = async () => {
            try {
                setLoading(true);
                const data = await adminDashboardService.getLatestSales();
                setLatestSalesData(data);
            } catch (error) {
                console.error("Error fetching latest sales:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLatestSales();
    }, []);

    return (
        <div className="border border-light-subtle py-3 px-3 px-sm-2 px-xl-3 bg-white light-shadow rounded-3 mt-3">
            <div className="main-text-dark-color fw-bold-600">
                Latest Sales
            </div>
            {loading ? (
                <div className="last-orders-list-holder">
                    <div className="order-line mt-3 main-loading-effect" style={{height: '26px'}}/>
                    <div className="order-line mt-3 main-loading-effect" style={{height: '26px'}}/>
                    <div className="order-line mt-3 main-loading-effect" style={{height: '26px'}}/>
                    <div className="order-line mt-3 main-loading-effect" style={{height: '26px'}}/>
                    <div className="order-line mt-3 main-loading-effect" style={{height: '26px'}}/>
                </div>
            ) : (
                latestSalesData.length > 0 ? (
                    <div className="last-orders-list-holder">
                        {latestSalesData.map(sale => (
                            <div className="order-line mt-3" key={sale.id}>
                                <div className="d-flex align-items-center">
                                    <div className="order-info lh-1 w-50">
                                        <div className="order-name small one-line-limit">
                                            {sale.product_title}
                                        </div>
                                        <div className="order-email text-real-small one-line-limit gray-text-color">
                                            {sale.category_name}
                                        </div>
                                    </div>
                                    <div className="w-50 d-flex align-items-center justify-content-between ps-2">
                                        <div className="fw-bold-500 text-real-small">
                                            ${parseFloat(sale.amount).toFixed(2)}
                                        </div>
                                        <div className="order-status text-real-small gray-text-color">
                                            {getDate(sale.created_date)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <></>
                )
            )}
        </div>
    );
};

export default LatestSales;