import {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {adminDashboardService} from "../../../_services";

const RegisteredUsers = () => {
    const [filterBy, setFilterBy] = useState('days');
    const [loading, setLoading] = useState(true);
    const [series, setSeries] = useState([
        {name: 'Free users', data: [0, 0, 0, 0, 0, 0, 0]},
        {name: 'Premium users', data: [0, 0, 0, 0, 0, 0, 0]}
    ]);
    const [options, setOptions] = useState({
        chart: {
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        grid: {
            borderColor: '#f1f1f1',
            row: {
                colors: ['#fff'],
                opacity: 0.5
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#008FFB', '#775DD0FF'],
        xaxis: {
            categories: []
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetY: 10,
        },
        markers: {
            size: 4
        },
    });

    useEffect(() => {
        const fetchRegisteredUsersDetails = async () => {
            try {
                setLoading(true);
                const data = await adminDashboardService.getRegisteredUsersDetails(filterBy);

                setSeries([
                    {
                        name: 'Free Users',
                        data: data.seriesResult.freeUsers.map(value => Number(value) || 0)
                    },
                    {
                        name: 'Premium Users',
                        data: data.seriesResult.premiumUsers.map(value => Number(value) || 0)
                    }
                ]);

                setOptions(prevOptions => ({
                    ...prevOptions,
                    xaxis: {
                        ...prevOptions.xaxis,
                        type: 'category',
                        categories: data.categoriesResult
                    }
                }));
            } catch (error) {
                console.error("Error fetching user details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRegisteredUsersDetails();
    }, [filterBy]);

    const changeFilterBy = (filterBy) => {
        setFilterBy(filterBy);
    };

    return (
        <div className="border border-light-subtle pt-3 pb-1 px-3 px-sm-2 px-md-3 bg-white light-shadow rounded-3 mt-3">
            <div className="d-flex align-items-center justify-content-between">
                <div className="main-text-dark-color fw-bold-600">
                    Registered Users
                </div>
                <div className="form-group">
                    <select
                        className="form-select form-select-sm text-real-small"
                        onChange={(e) => changeFilterBy(e.target.value)}
                        id="filterBy"
                    >
                        <option value="days">Days</option>
                        <option value="months">Months</option>
                        <option value="years">Years</option>
                    </select>
                </div>
            </div>
            {loading ? (
                <div className="main-loading-effect mt-2 mb-1 rounded-2" style={{height: "300px", width: "100%"}}/>
            ) : (
                <div className="">
                    <div id="chart">
                        <ReactApexChart options={options} series={series} type="line" height={300}/>
                    </div>
                    <div id="html-dist"></div>
                </div>
            )}
        </div>
    );
};

export default RegisteredUsers;