import {
    Header,
    SummaryCards,
    RegisteredUsers,
    UsersStatus,
    RevenueDetails,
    LatestUsers,
    LatestSales
} from "./Private";

const Dashboard = () => {

    return (
        <>
            <Header title="Dashboard"/>
            <div
                className="the-main-content d-flex flex-column position-relative pt-3 pb-lg-3 px-3 overflow-x-hidden">
                {/*Start Page Content*/}
                <div className="main-dashboard-page mb-3">
                    <div className="summary-cards-holder mb-3">
                        <SummaryCards/>
                    </div>
                    <div className="details-stats-holder">
                        <div className="users-stats-holder row align-items-start m-0 gx-3">
                            <div className="col-md-7 col-xl-8 mb-3">
                                <RevenueDetails/>
                                <RegisteredUsers/>
                            </div>
                            <div className="col-md-5 col-xl-4 mb-3">
                                <UsersStatus/>
                                <LatestSales/>
                                <LatestUsers/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*End Page Content*/}
            </div>
        </>
    );
};

export default Dashboard;