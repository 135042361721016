import {FaCircleDollarToSlot, FaAward, FaUsers, FaBook} from "react-icons/fa6";
import {useEffect, useState} from "react";
import {adminDashboardService} from "../../../_services";

const SummaryCards = () => {
    const [totalsData, setTotalsData] = useState({
        usersTotal: 0,
        challengesTotal: 0,
        ebooksTotal: 0,
        revenueTotal: 0
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTotalsStats = async () => {
            try {
                setLoading(true);
                const data = await adminDashboardService.getTotalStats();
                setTotalsData(data);
            } catch (error) {
                console.error("Error fetching totals stats:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTotalsStats();
    }, []);

    return (
        <div className="summary-content row m-0 gx-3">
            {loading ? (
                <>
                    <div className="col-6 col-md-3 mb-3 mb-md-0">
                        <div className="border border-light-subtle p-3 bg-white light-shadow rounded-3"
                             style={{height: "85px"}}>
                            <div className="d-flex gray-text-color">
                                <div className="icon-holder d-flex align-items-center justify-content-center">
                                    <FaUsers size="15"/>
                                </div>
                                <div className="small ms-1">
                                    Total users
                                </div>
                            </div>
                            <div className="rounded-3 main-loading-effect mt-2"
                                 style={{height: "21px"}}/>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb-3 mb-md-0">
                        <div className="border border-light-subtle p-3 bg-white light-shadow rounded-3"
                             style={{height: "85px"}}>
                            <div className="d-flex gray-text-color">
                                <div className="icon-holder d-flex align-items-center justify-content-center">
                                    <FaCircleDollarToSlot size="15"/>
                                </div>
                                <div className="small ms-1">
                                    Total revenue
                                </div>
                            </div>
                            <div className="rounded-3 main-loading-effect mt-2"
                                 style={{height: "21px"}}/>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb-3 mb-md-0">
                        <div className="border border-light-subtle p-3 bg-white light-shadow rounded-3"
                             style={{height: "85px"}}>
                            <div className="d-flex gray-text-color">
                                <div className="icon-holder d-flex align-items-center justify-content-center">
                                    <FaAward size="15"/>
                                </div>
                                <div className="small ms-1">
                                    Total challenges
                                </div>
                            </div>
                            <div className="rounded-3 main-loading-effect mt-2"
                                 style={{height: "21px"}}/>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb-3 mb-md-0">
                        <div className="border border-light-subtle p-3 bg-white light-shadow rounded-3"
                             style={{height: "85px"}}>
                            <div className="d-flex gray-text-color">
                                <div className="icon-holder d-flex align-items-center justify-content-center">
                                    <FaBook size="15"/>
                                </div>
                                <div className="small ms-1">
                                    Total ebooks
                                </div>
                            </div>
                            <div className="rounded-3 main-loading-effect mt-2"
                                 style={{height: "21px"}}/>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="col-6 col-md-3 mb-3 mb-md-0">
                        <div
                            className="border border-light-subtle p-3 bg-white light-shadow rounded-3">
                            <div className="d-flex gray-text-color">
                                <div className="icon-holder d-flex align-items-center justify-content-center">
                                    <FaUsers size="15"/>
                                </div>
                                <div className="small ms-1">
                                    Total users
                                </div>
                            </div>
                            <div className="fw-bold-600 fs-5">
                                {totalsData.usersTotal}
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 mb-3 mb-md-0">
                        <div
                            className="border border-light-subtle p-3 bg-white light-shadow rounded-3">
                            <div className="d-flex gray-text-color">
                                <div className="icon-holder d-flex align-items-center justify-content-center">
                                    <FaCircleDollarToSlot size="15"/>
                                </div>
                                <div className="small ms-1">
                                    Total revenue
                                </div>
                            </div>
                            <div className="fw-bold-600 fs-5">
                                ${parseFloat(totalsData.revenueTotal).toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div
                            className="border border-light-subtle p-3 bg-white light-shadow rounded-3">
                            <div className="d-flex gray-text-color">
                                <div className="icon-holder d-flex align-items-center justify-content-center">
                                    <FaAward size="15"/>
                                </div>
                                <div className="small ms-1">
                                    Total challenges
                                </div>
                            </div>
                            <div className="fw-bold-600 fs-5">
                                {totalsData.challengesTotal}
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3">
                        <div
                            className="border border-light-subtle p-3 bg-white light-shadow rounded-3">
                            <div className="d-flex gray-text-color">
                                <div className="icon-holder d-flex align-items-center justify-content-center">
                                    <FaBook size="15"/>
                                </div>
                                <div className="small ms-1">
                                    Total ebooks
                                </div>
                            </div>
                            <div className="fw-bold-600 fs-5">
                                {totalsData.ebooksTotal}
                            </div>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
};

export default SummaryCards;