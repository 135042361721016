import Axios from "../ServiceCaller";

let getQuizCategories = async () => {
    const {data} = await Axios.get("/public-quizzes/quiz-categories");
    return data.QuizCategories;
};
let getQuizCategoryById = async (id) => {
    const {data} = await Axios.get(`/public-quizzes/quiz-categories/${id}`);
    return data.QuizCategory;
};

let getQuizzes = async (id) => {
    const {data} = await Axios.get(`/public-quizzes/quiz-category/${id}/quizzes`);
    return data.Quizzes;
};
let getQuizById = async (id) => {
    const {data} = await Axios.get(`/public-quizzes/quizzes/${id}`);
    return data.Quiz;
};

let getQuizQuestions = async (quiz) => {
    const {data} = await Axios.get(`/public-quizzes/quiz/${quiz}/questions`);
    return data.QuizQuestions;
};

let getGlobalRanking = async () => {
    const {data} = await Axios.get("/public-quizzes/global-ranking");
    return data.GlobalRanking;
};

export const publicQuizService = {
    getQuizCategories,
    getQuizCategoryById,
    getQuizzes,
    getQuizById,
    getQuizQuestions,
    getGlobalRanking,
};