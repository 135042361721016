import Axios from "../ServiceCaller";

let getTotalStats = async () => {
    const {data} = await Axios.get('/admin-dashboard/total-stats');
    return data.TotalStats;
};

let getLatestSales = async () => {
    const {data} = await Axios.get('/admin-dashboard/latest-sales');
    return data.LatestSales;
};

let getLatestUsers = async () => {
    const {data} = await Axios.get('/admin-dashboard/latest-users');
    return data.LatestUsers;
};

let getRegisteredUsersStatus = async () => {
    const {data} = await Axios.get('/admin-dashboard/registered-users-status');
    return data.RegisteredUsersStatus;
};

let getRevenueDetails = async (filterBy) => {
    const {data} = await Axios.get(`/admin-dashboard/revenue-details/${filterBy}`);
    return data.RevenueDetails;
};

let getRegisteredUsersDetails = async (filterBy) => {
    const {data} = await Axios.get(`/admin-dashboard/registered-users-details/${filterBy}`);
    return data.RegisteredUsersDetails;
};

export const adminDashboardService = {
    getTotalStats,
    getLatestSales,
    getLatestUsers,
    getRegisteredUsersStatus,
    getRevenueDetails,
    getRegisteredUsersDetails
};